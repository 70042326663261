<template>
  <div class="d-flex flex-column pt-4">
    <div class="d-flex justify-content-between">
      <h1>System Logs</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>

    <div class="d-flex flex-column pt-4">
      <!-- query form -->
      <b-card-group>
        <b-card class="bg-surface-2">
          <!--        <div class="d-flex flex-row">-->
          <!--          <div class="d-flex flex-column w-100">-->
          <!--            <div>Service Name</div>-->
          <!--            <v-select-->
          <!--              class="filter-select mr-2"-->
          <!--              v-model="queryForm.service_name"-->
          <!--              :label="'name'"-->
          <!--              :options="servicesOptions"-->
          <!--              :reduce="(item) => item.value"-->
          <!--              @input="getData"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="d-flex flex-column w-100">-->
          <!--            <div>Log Level</div>-->
          <!--            <v-select-->
          <!--              class="filter-select mr-2"-->
          <!--              v-model="queryForm.log_level"-->
          <!--              :label="'name'"-->
          <!--              :options="levelOptions"-->
          <!--              :reduce="(item) => item.value"-->
          <!--              @input="getData"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div class="d-flex flex-column w-100">-->
          <!--            <div>Date Time Range</div>-->
          <!--            <date-range-picker-->
          <!--              ref="picker"-->
          <!--              class="w-100"-->
          <!--              v-model="queryForm.dateRange"-->
          <!--              :locale-data="{-->
          <!--                firstDay: 1,-->
          <!--                format: 'dd-mm-yyyy HH:mm:ss'-->
          <!--              }"-->
          <!--              :time-picker-increment="1"-->
          <!--              :timePicker="true"-->
          <!--              :time-picker-seconds="true"-->
          <!--            >-->
          <!--              <template v-slot:input="picker">-->
          <!--                {{ picker.startDate | dateTime }} - -->
          <!--                {{ picker.endDate | dateTime }}-->
          <!--              </template>-->
          <!--            </date-range-picker>-->
          <!--          </div>-->
          <!--          <div class="d-flex flex-column justify-content-end">-->
          <!--            <b-button-->
          <!--              size="md"-->
          <!--              variant="light"-->
          <!--              @click="getData"-->
          <!--              v-b-tooltip.hover-->
          <!--              title="Search"-->
          <!--            >-->
          <!--              <i class="fas fa-search"></i-->
          <!--            ></b-button>-->
          <!--          </div>-->
          <!--          <div class="d-flex flex-column justify-content-end">-->
          <!--            <b-button-->
          <!--              size="md"-->
          <!--              variant="dark"-->
          <!--              @click="clearFilter"-->
          <!--              v-b-tooltip.hover-->
          <!--              title="Reset"-->
          <!--            >-->
          <!--              <i class="fas fa-undo-alt"></i-->
          <!--            ></b-button>-->
          <!--          </div>-->
          <!--        </div>-->
          <b-row>
            <b-col lg="4">
              <div>Date Time Range</div>
              <date-range-picker
                :opens="opens"
                :autoApply="false"
                @update="getListSelect"
                ref="picker"
                class="w-100"
                v-model="queryForm.dateRange"
                :locale-data="{
                  firstDay: 1,
                  format: 'dd-mm-yyyy HH:mm:ss'
                }"
                :time-picker-increment="1"
                :timePicker="true"
                :time-picker-seconds="true"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | dateTime }} -
                  {{ picker.endDate | dateTime }}
                </template>
              </date-range-picker>
            </b-col>
            <b-col lg="4">
              <div>Service Name</div>
              <v-select
                class="filter-select mr-2"
                v-model="queryForm.service_name"
                :label="'name'"
                :options="servicesOptions"
                :reduce="(item) => item.value"
                @input="getData"
              />
            </b-col>
            <b-col lg="4">
              <div>Log Level</div>
              <v-select
                class="filter-select mr-2"
                v-model="queryForm.log_level"
                :label="'name'"
                :options="levelOptions"
                :reduce="(item) => item.value"
                @input="getData"
              />
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end" no-gutters>
            <b-button
              size="sm"
              variant="dark"
              @click="clearFilter"
              class="d-flex justify-content-end mr-1"
              >Clear
            </b-button>
            <b-button size="sm" variant="light" @click="getData">
              Search
            </b-button>
          </b-row>
        </b-card>
      </b-card-group>

      <!-- <div class="flex bg-surface-2">
      <div class="row p-2">
        <div class="col">
          <div>Service Name</div>
          <v-select
            class="filter-select"
            v-model="queryForm.service_name"
            :label="'name'"
            :options="servicesOptions"
            :reduce="(item) => item.value"
          />
        </div>

        <div class="col">
          <div>Log Level</div>
          <v-select
            class="filter-select"
            v-model="queryForm.log_level"
            :label="'name'"
            :options="levelOptions"
            :reduce="(item) => item.value"
          />
        </div>

        <div class="col">
          <div>Date Time Range</div>
          <date-range-picker
            ref="picker"
            class="w-100"
            v-model="queryForm.dateRange"
            :locale-data="{
              firstDay: 1,
              format: 'dd-mm-yyyy HH:mm:ss'
            }"
            :time-picker-increment="1"
            :timePicker="true"
            :time-picker-seconds="true"
          >
            <template v-slot:input="picker">
              {{ picker.startDate | dateTime }} -
              {{ picker.endDate | dateTime }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="d-flex justify-content-end p-2">
        <b-button
          size="sm"
          variant="secondary"
          @click="clearFilter"
          class="mr-1"
          >Reset</b-button
        >
        <b-button size="sm" variant="primary" @click="getData">
          Search</b-button
        >
      </div>
    </div> -->

      <!--    <div class="d-flex justify-content-between pt-4">-->
      <!--      <div class="d-flex justify-content-start">-->
      <!--        <b-button v-b-tooltip.hover title="Download PDF" :disabled="true">-->
      <!--          <i class="fas fa-file-pdf"></i>-->
      <!--        </b-button>-->
      <!--        <div v-b-tooltip.hover title="Download CSV">-->
      <!--          <download-excel-->
      <!--            class="ml-1 btn btn-secondary"-->
      <!--            :class="result.length === 0 ? 'disabled' : null"-->
      <!--            :data="result"-->
      <!--            :fields="jsonFields"-->
      <!--            worksheet="My Worksheet"-->
      <!--            type="csv"-->
      <!--            :title="-->
      <!--              queryForm.dateRange.startDate +-->
      <!--              ' to ' +-->
      <!--              queryForm.dateRange.endDate +-->
      <!--              ' System Logs'-->
      <!--            "-->
      <!--            :name="-->
      <!--              queryForm.dateRange.startDate +-->
      <!--              ' to ' +-->
      <!--              queryForm.dateRange.endDate +-->
      <!--              ' System Logs.csv'-->
      <!--            "-->
      <!--          >-->
      <!--            <i class="fas fa-file-csv"></i>-->
      <!--          </download-excel>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="d-flex justify-content-end w-30 d-inline-block mr-2">-->
      <!--        <b-form-input-->
      <!--          id="search_bar"-->
      <!--          size="sm"-->
      <!--          type="text"-->
      <!--          placeholder="Search..."-->
      <!--          v-model="searchTerms"-->
      <!--        ></b-form-input>-->
      <!--      </div>-->
      <!--    </div>-->

      <div class="d-flex justify-content-end pt-4 pb-2">
        <download-excel
          class="ml-1 btn btn-secondary"
          :class="result.length === 0 ? 'disabled' : null"
          :data="result"
          :fields="jsonVMSFields"
          worksheet="My Worksheet"
          type="csv"
          :name="
            queryForm.dateRange.startDate +
            ' to ' +
            queryForm.dateRange.endDate +
            ' System Logs.csv'
          "
        >
          <i class="fas fa-file-csv"></i> Export as CSV
        </download-excel>
      </div>

      <div class="d-flex">
        <!-- Main table element -->
        <b-table
          class="tableBorder"
          striped
          small
          show-empty
          sticky-header
          stacked="md"
          :busy="busy"
          :items.sync="result"
          :fields="resultTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="searchTerms"
          :filter-included-fields="[]"
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          @sort-changed="sortChanged"
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage - 1) * perPage }}
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | unixToDateTime }}
          </template>
          <template #cell(service_name)="data">
            {{ data.item.service_name | humanizeText }}
          </template>
          <template #cell(func_name)="data">
            {{ data.item.func_name | humanizeText }}
          </template>
          <template #cell(message)="data">
            {{ data.item.message }}
          </template>
          <template #cell(process_name)="data">
            {{ data.item.process_name }}
          </template>
          <template #cell(file_name)="data">
            <div class="d-flex flex-column">
              <div class="d-flex">
                {{ data.item.file_name }}
              </div>
              <h2>
                <b-badge variant="info"
                  >Line No: {{ data.item.line_no }}</b-badge
                >
              </h2>
            </div>
          </template>
          <template #cell(level_name)="data">
            <div v-if="data.item.level_name == 'INFO'" class="w-50">
              <h2>
                <b-badge variant="info">{{ data.item.level_name }}</b-badge>
              </h2>
            </div>
            <div v-if="data.item.level_name == 'ERROR'" class="w-50">
              <h2>
                <b-badge variant="danger">{{ data.item.level_name }}</b-badge>
              </h2>
            </div>
            <div v-if="data.item.level_name == 'CRITICAL'" class="w-50">
              <h2>
                <b-badge variant="warning">{{ data.item.level_name }}</b-badge>
              </h2>
            </div>
            <div v-if="data.item.level_name == 'WARNING'" class="w-50">
              <h2>
                <b-badge variant="warning">{{ data.item.level_name }}</b-badge>
              </h2>
            </div>
            <div v-if="data.item.level_name == 'DEBUG'" class="w-50">
              <h2>
                <b-badge variant="secondary">{{
                  data.item.level_name
                }}</b-badge>
              </h2>
            </div>
          </template>
          <template #cell(thread_name)="data">
            {{ data.item.thread_name }}
          </template>
        </b-table>
      </div>
      <!--pagination-->
      <div class="d-flex justify-content-between w-100" v-if="!busy && !search">
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="handlePerPageChange"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import _ from "lodash";

export default {
  components: {
    downloadExcel: JsonExcel,
    DateRangePicker
  },
  data: function () {
    let yesterday = moment().subtract(1, "days").toDate();
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);

    let today = moment().toDate();

    return {
      opens: "right",
      result: [],
      resultTableFields: [
        {
          key: "index",
          label: "No.",
          thStyle: { width: "3%" }
        },
        {
          key: "created_at",
          label: "Created Time",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "service_name",
          label: "Service Name",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "func_name",
          label: "Function Name",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "message",
          label: "Message",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "level_name",
          label: "Level",
          sortable: true,
          thStyle: { width: "auto" }
        }
      ],
      sortBy: null,
      sortDesc: null,
      sortDirection: "desc",
      // pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],
      pageOptions: [5, 10, 15],
      // table search
      searchTerms: "",
      search: false,
      busy: false,

      queryForm: {
        service_name: null,
        log_level: null,
        dateRange: {
          startDate: yesterday,
          endDate: today
        }
      },

      servicesOptions: [
        /* { value: "sc_acsservice", name: "Acs Service" },
        { value: "sc_auditlogservice", name: "Audit Log Service" },
        { value: "sc_logservice", name: "Log Service" },
        { value: "sc_authservice", name: "Auth Service" },
        { value: "sc_faceservice", name: "Face Service" },
        { value: "sc_messageservice", name: "Message Service" },
        { value: "sc_monitorservice", name: "Monitor Service" },
        { value: "sc_visitorservice", name: "Visitor Service" },
        { value: "sc_eventservice", name: "Event Service" } */
      ],
      levelOptions: [
        /* { value: "CRITICAL", name: "Critical" },
        { value: "ERROR", name: "Error" },
        { value: "WARNING", name: "Warning" },
        { value: "INFO", name: "Info" },
        { value: "DEBUG", name: "Debug" },
        { value: "NOTSET", name: "Not Set" } */
      ],

      // export to CSV
      jsonVMSFields: {
        "Created Time": {
          field: "created_at",
          callback: (value) => {
            try {
              return moment(value * 1000).format("YYYY-MM-DD HH:mm:ss");
            } catch (e) {
              return "-";
            }
          }
        },
        "Service Name": {
          field: "service_name",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        "Function Name": {
          field: "func_name",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        Message: {
          field: "message"
        },
        // "Process Name": {
        //   field: "process_name"
        // },
        // "File Name": {
        //   field: "file_name"
        // },
        Level: {
          field: "level_name"
        }
        // Thread: {
        //   field: "thread_name"
        // }
      }
    };
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 15;
      }
      this.getData();
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
    }
  },
  mounted: function () {
    this.getData();
  },

  methods: {
    getData() {
      this.getPagination();
      this.getSystemLogs();
    },
    getPagination: function () {
      let $this = this;
      let query = {};

      $this.busy = true;
      if (this.perPage === "all") {
        query = {
          start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
          end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
          log_level: $this.queryForm.log_level,
          service_name: $this.queryForm.service_name
        };
      } else {
        query = {
          start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
          end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
          log_level: $this.queryForm.log_level,
          service_name: $this.queryForm.service_name,
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/logservice/logs/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");
      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;

                this.busy = false;
                this.search = false;
                break outer_loop;
              }
            }
          }

          if (result[validUrl]["total"] !== undefined) {
            this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            this.perPage = parseInt(result[validUrl]["size"]);
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    getSystemLogs: function () {
      this.busy = true;

      let $this = this;
      let query = {};
      query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
        log_level: $this.queryForm.log_level,
        service_name: $this.queryForm.service_name
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/logservice/logs/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");
      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;

                this.busy = false;
                this.search = false;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] != undefined) {
            this.result = result[validUrl]["audit_logs"];

            if (this.result == undefined) {
              this.result = result[validUrl];
            }
          }

          this.getListSelect();
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    getListSelect: function () {
      let $this = this;
      let query = {};
      query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString()
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");
      let API_URL = $this.getAPIServerURL + "/api/logservice/logs/?";
      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let logservice_url in result) {
              validUrl = logservice_url;
              this.busy = false;
              this.search = false;
              break outer_loop;
            }
            if (result[validUrl] != undefined) {
              for (let key_result in result[validUrl]) {
                let output = [];
                output.push({
                  value: result[validUrl][key_result].service_name,
                  name: _.startCase(result[validUrl][key_result].service_name)
                });
                output.forEach(function (item) {
                  let existing = $this.servicesOptions.filter(function (v, i) {
                    return v.name == item.name;
                  });
                  if (existing.length == 0) {
                    if (typeof item.value == "string") {
                      $this.servicesOptions.push(item);
                    }
                  }
                });

                let output2 = [];
                output2.push({
                  value: result[validUrl][key_result].level_name,
                  name: _.startCase(result[validUrl][key_result].level_name)
                });
                output2.forEach(function (item) {
                  let existing2 = $this.levelOptions.filter(function (v, i) {
                    return v.name == item.name;
                  });
                  if (existing2.length == 0) {
                    if (typeof item.value == "string") {
                      $this.levelOptions.push(item);
                    }
                  }
                });
              }
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    //sort
    sortChanged(e) {
      console.log("inside", e);
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      if (this.sortBy == "created_at") {
        if (this.sortDesc) {
          this.result = _.orderBy(this.result, this.sortBy, "desc");
        }
        if (!this.sortDesc) {
          this.result = _.orderBy(this.result, this.sortBy, "asc");
        }
      } else {
        if (this.sortDesc) {
          this.result.sort((x, y) =>
            y[this.sortBy].localeCompare(x[this.sortBy])
          );
        } else {
          this.result.sort((x, y) =>
            x[this.sortBy].localeCompare(y[this.sortBy])
          );
        }
      }

      if (this.sortBy == "message") {
        if (this.sortDesc) {
          this.result.sort((x, y) =>
            y[this.sortBy].localeCompare(x[this.sortBy], undefined, {
              numeric: true,
              sensitivity: "base"
            })
          );
        } else {
          this.result.sort((x, y) =>
            x[this.sortBy].localeCompare(y[this.sortBy], undefined, {
              numeric: true,
              sensitivity: "base"
            })
          );
        }
      }
      // console.log("result", this.result);
    },

    clearFilter: function () {
      let yesterday = moment().subtract(1, "days").toDate();
      yesterday.setHours(0);
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);
      yesterday.setMilliseconds(0);

      let today = moment().toDate();
      this.$router.push({
        name: this.$route.query.parents_url
      });
      this.queryForm = {
        service_name: null,
        log_level: null,
        dateRange: {
          startDate: yesterday,
          endDate: today
        }
      };
      this.getData();
    },

    downloadPdf() {},
    /* handle page change */
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    startDownloadCSV() {
      if (this.result.length > 0) {
        this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text:
            "We are generating CSV report with " +
            this.result.length +
            " events",
          message_type: "info"
        });
      }
    },
    finishDownloadCSV() {
      this.$store.dispatch("session/addGlobalAlertMessage", {
        message_text:
          "CSV report with " +
          this.result.length +
          " events has been downloaded successfully",
        message_type: "info"
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../App";
</style>
